<template>
  <span>
    <News :del="true"></News>
    <v-btn
      fixed
      dark
      fab
      style="bottom: 80px"
      right
      large
      color="pink"
      @click="showNewNewsDialog = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="showNewNewsDialog" max-width="700">
      <v-card>
        <v-card-title>
          <span class="headline">Create News</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newNews.name"
                  prepend-icon="mdi-calendar-blank"
                  label="Name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  prepend-icon="mdi-calendar-text"
                  v-model="newNews.description"
                  label="Description*"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showNewNewsDialog = false">
            Close
          </v-btn>
          <v-btn color="success" text @click="save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { db } from "@/plugins/firebase";
import News from "@/components/News/News";

export default {
  fiery: true,
  components: { News },
  data() {
    return {
      newNews: {
        name: "",
        description: "",
      },
      showNewNewsDialog: false,
    };
  },
  computed: {},
  methods: {
    save() {
      const news = this.$fiery(db.collection("News"), {});
      const newNewsWithDate = { ...this.newNews, date: new Date() };
      this.$fiery.create(news, newNewsWithDate);
      this.showNewNewsDialog = false;
      this.newNews = {
        name: "",
        description: "",
      };
    },
  },
};
</script>
